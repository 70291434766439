import React from 'react';
import Square from '../square/square';


function GameList(props){
    const renderSquare = (id, bonus_letter = 1, bonus_word = 1) => {
        return (
            <Square
                id={id}
                letter={props.board[id][0]}
                bonus_letter={bonus_letter}
                bonus_word={bonus_word}
            />
    )};

    return (
        <div className="small-board" style={{ maxWidth: "450px", marginRight: "10px", display: "inline-block"}}>
            <p><span style={{ float: "left" }}>{props.name1 + ' : ' + props.score1}</span> <span style={{ float: "right" }}>{props.name2 + ' : ' + props.score2}</span></p>
            <table style={{tableLayout: "fixed", width:"100%"}}>
                <tbody>
                    <tr className="board-row">
                        {renderSquare(0, 1, 3)}
                        {renderSquare(1)}
                        {renderSquare(2)}
                        {renderSquare(3, 2)}
                        {renderSquare(4)}
                        {renderSquare(5)}
                        {renderSquare(6)}
                        {renderSquare(7, 1, 3)}
                        {renderSquare(8)}
                        {renderSquare(9)}
                        {renderSquare(10)}
                        {renderSquare(11, 2)}
                        {renderSquare(12)}
                        {renderSquare(13)}
                        {renderSquare(14, 1, 3)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(15)}
                        {renderSquare(16, 1, 2)}
                        {renderSquare(17)}
                        {renderSquare(18)}
                        {renderSquare(19)}
                        {renderSquare(20, 3)}
                        {renderSquare(21)}
                        {renderSquare(22)}
                        {renderSquare(23)}
                        {renderSquare(24, 3)}
                        {renderSquare(25)}
                        {renderSquare(26)}
                        {renderSquare(27)}
                        {renderSquare(28, 1, 2)}
                        {renderSquare(29)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(30)}
                        {renderSquare(31)}
                        {renderSquare(32, 1, 2)}
                        {renderSquare(33)}
                        {renderSquare(34)}
                        {renderSquare(35)}
                        {renderSquare(36, 2)}
                        {renderSquare(37)}
                        {renderSquare(38, 2)}
                        {renderSquare(39)}
                        {renderSquare(40)}
                        {renderSquare(41)}
                        {renderSquare(42, 1, 2)}
                        {renderSquare(43)}
                        {renderSquare(44)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(45, 2)}
                        {renderSquare(46)}
                        {renderSquare(47)}
                        {renderSquare(48, 1, 2)}
                        {renderSquare(49)}
                        {renderSquare(50)}
                        {renderSquare(51)}
                        {renderSquare(52, 2)}
                        {renderSquare(53)}
                        {renderSquare(54)}
                        {renderSquare(55)}
                        {renderSquare(56, 1, 2)}
                        {renderSquare(57)}
                        {renderSquare(58)}
                        {renderSquare(59, 2)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(60)}
                        {renderSquare(61)}
                        {renderSquare(62)}
                        {renderSquare(63)}
                        {renderSquare(64, 1, 2)}
                        {renderSquare(65)}
                        {renderSquare(66)}
                        {renderSquare(67)}
                        {renderSquare(68)}
                        {renderSquare(69)}
                        {renderSquare(70, 1, 2)}
                        {renderSquare(71)}
                        {renderSquare(72)}
                        {renderSquare(73)}
                        {renderSquare(74)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(75)}
                        {renderSquare(76, 3)}
                        {renderSquare(77)}
                        {renderSquare(78)}
                        {renderSquare(79)}
                        {renderSquare(80, 3)}
                        {renderSquare(81)}
                        {renderSquare(82)}
                        {renderSquare(83)}
                        {renderSquare(84, 3)}
                        {renderSquare(85)}
                        {renderSquare(86)}
                        {renderSquare(87)}
                        {renderSquare(88, 3)}
                        {renderSquare(89)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(90)}
                        {renderSquare(91)}
                        {renderSquare(92, 2)}
                        {renderSquare(93)}
                        {renderSquare(94)}
                        {renderSquare(95)}
                        {renderSquare(96, 2)}
                        {renderSquare(97)}
                        {renderSquare(98, 2)}
                        {renderSquare(99)}
                        {renderSquare(100)}
                        {renderSquare(101)}
                        {renderSquare(102, 2)}
                        {renderSquare(103)}
                        {renderSquare(104)}
                    </tr>
                    {/*  middle */}
                    <tr className="board-row">
                        {renderSquare(105, 1, 3)}
                        {renderSquare(106)}
                        {renderSquare(107)}
                        {renderSquare(108, 2)}
                        {renderSquare(109)}
                        {renderSquare(110)}
                        {renderSquare(111)}
                        {renderSquare(112)}
                        {renderSquare(113)}
                        {renderSquare(114)}
                        {renderSquare(115)}
                        {renderSquare(116, 2)}
                        {renderSquare(117)}
                        {renderSquare(118)}
                        {renderSquare(119, 1, 3)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(120)}
                        {renderSquare(121)}
                        {renderSquare(122, 2)}
                        {renderSquare(123)}
                        {renderSquare(124)}
                        {renderSquare(125)}
                        {renderSquare(126, 2)}
                        {renderSquare(127)}
                        {renderSquare(128, 2)}
                        {renderSquare(129)}
                        {renderSquare(130)}
                        {renderSquare(131)}
                        {renderSquare(132, 2)}
                        {renderSquare(133)}
                        {renderSquare(134)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(135)}
                        {renderSquare(136, 3)}
                        {renderSquare(137)}
                        {renderSquare(138)}
                        {renderSquare(139)}
                        {renderSquare(140, 3)}
                        {renderSquare(141)}
                        {renderSquare(142)}
                        {renderSquare(143)}
                        {renderSquare(144, 3)}
                        {renderSquare(145)}
                        {renderSquare(146)}
                        {renderSquare(147)}
                        {renderSquare(148, 3)}
                        {renderSquare(149)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(150)}
                        {renderSquare(151)}
                        {renderSquare(152)}
                        {renderSquare(153)}
                        {renderSquare(154, 1, 2)}
                        {renderSquare(155)}
                        {renderSquare(156)}
                        {renderSquare(157)}
                        {renderSquare(158)}
                        {renderSquare(159)}
                        {renderSquare(160, 1, 2)}
                        {renderSquare(161)}
                        {renderSquare(162)}
                        {renderSquare(163)}
                        {renderSquare(164)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(165, 2)}
                        {renderSquare(166)}
                        {renderSquare(167)}
                        {renderSquare(168, 1, 2)}
                        {renderSquare(169)}
                        {renderSquare(170)}
                        {renderSquare(171)}
                        {renderSquare(172, 2)}
                        {renderSquare(173)}
                        {renderSquare(174)}
                        {renderSquare(175)}
                        {renderSquare(176, 1, 2)}
                        {renderSquare(177)}
                        {renderSquare(178)}
                        {renderSquare(179, 2)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(180)}
                        {renderSquare(181)}
                        {renderSquare(182, 1, 2)}
                        {renderSquare(183)}
                        {renderSquare(184)}
                        {renderSquare(185)}
                        {renderSquare(186, 2)}
                        {renderSquare(187)}
                        {renderSquare(188, 2)}
                        {renderSquare(189)}
                        {renderSquare(190)}
                        {renderSquare(191)}
                        {renderSquare(192, 1, 2)}
                        {renderSquare(193)}
                        {renderSquare(194)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(195)}
                        {renderSquare(196, 1, 2)}
                        {renderSquare(197)}
                        {renderSquare(198)}
                        {renderSquare(199)}
                        {renderSquare(200, 3)}
                        {renderSquare(201)}
                        {renderSquare(202)}
                        {renderSquare(203)}
                        {renderSquare(204, 3)}
                        {renderSquare(205)}
                        {renderSquare(206)}
                        {renderSquare(207)}
                        {renderSquare(208, 1, 2)}
                        {renderSquare(209)}
                    </tr>
                    <tr className="board-row">
                        {renderSquare(210, 1, 3)}
                        {renderSquare(211)}
                        {renderSquare(212)}
                        {renderSquare(213, 2)}
                        {renderSquare(214)}
                        {renderSquare(215)}
                        {renderSquare(216)}
                        {renderSquare(217, 1, 3)}
                        {renderSquare(218)}
                        {renderSquare(219)}
                        {renderSquare(220)}
                        {renderSquare(221, 2)}
                        {renderSquare(222)}
                        {renderSquare(223)}
                        {renderSquare(224, 1, 3)}
                    </tr>
                </tbody>
            </table>
            


        </div>
    )    
}

export default GameList;