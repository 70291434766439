import React, { useEffect, useState } from 'react'
import socketIOClient from 'socket.io-client';
import Game from '../game/game';
import shortid from 'shortid';
import './client.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import GameList from '../gameList/gameList';

const ENDPOINT = process.env.REACT_APP_URL_BACKEND;
// const ENDPOINT = 'backend.ordissimots.ordissinaute.local';
var socket = socketIOClient(ENDPOINT);

window.gtag('event', 'Main menu', {
    'event_category': 'scrabble',
    'event_label': 'Main menu'
})

function Client(){
    const [isPlaying, setIsPlaying] = useState(false);
    const [roomId, setRoomId] = useState(null);
    const [opponentId, setOpponentId] = useState(null);
    const [AvailableRooms, setAvailableRooms] = useState([]);
    const [currentGames, setCurrentGames] = useState([]);
    const [playerNames, setPlayerNames] = useState([]);
    const [connectedPlayers, setConnectedPlayers] = useState(0);

    useEffect(() => {
        let mounted = true;
        socket.on('GameStart', (id, opponentId, playerName, opponentName) =>{
            if(mounted){
                // Set localstorage to use later(reconnect)
                localStorage.setItem('roomId', id)
                localStorage.setItem('socketId', socket.id)
                setRoomId(id);
                setIsPlaying(prevState => !prevState);
                setOpponentId(opponentId);
                setPlayerNames([playerName, opponentName]);
                mounted = false;
                Swal.close();
                window.gtag('event', 'Game start', {
                    'event_category': 'scrabble',
                    'event_label': 'Game start'
                })
            }
        })

        socket.on('connect', () =>{
            if (mounted){
                // attempt to reconnect
                var previousGameId = localStorage.getItem('roomId')
                var previousSocketId= localStorage.getItem('socketId')
                if(previousGameId && previousSocketId){
                    Swal.fire({
                        title: "Souhaitez vous continuer la partie précédente?",
                        confirmButtonText: "Oui, continuer",
                        denyButtonText: "Non, ne pas continuer",  
                        showDenyButton: true 
                    }).then((res) =>{
                        if(res.isConfirmed){
                            socket.emit('reconnectToGame', previousSocketId, (opponentId, playerName, opponentName) =>{
                              // Case game does not exists anymore
                              if(opponentId == null){
                                localStorage.removeItem('roomId');
                                localStorage.removeItem('socketId');
                                Swal.fire({
                                  title: 'Partie terminée',
                                  text: "Désolé cette partie n'est plus accessible :-(",
                                  confirmButtonText: "J'ai compris"
                                })
                              }
                              else{
                                setRoomId(previousGameId);
                                localStorage.setItem('socketId', socket.id)
                                setIsPlaying(true);
                                setOpponentId(opponentId);
                                setPlayerNames([playerName, opponentName]);
                                socket.emit('readyToReconnect');
                              }
                            });
                        }
                        if(res.isDenied){
                          localStorage.removeItem('roomId');
                          localStorage.removeItem('socketId');
                        }
                    })
                }
            }
        })
        socket.on('AvailableRooms', (data) => {
            if(mounted){
                setAvailableRooms(data);
            }
        })
        
        socket.on('currentGames', (games, nbConnectedPlayers) =>{
            if(mounted){
                setCurrentGames(games);
                setConnectedPlayers(nbConnectedPlayers)
            }
        })
        return () => {mounted = false}
    })
    


    var allRooms = [];
    if (AvailableRooms.length > 0){
        allRooms = AvailableRooms[0].map((room) =>
            <tr key={room}>
                <td>Partie de {AvailableRooms[1][room]}</td>
                <td>1/2</td>
                <td><button style={{ display: "block", width: "100%", border: 'none' }} onClick={() => joindRandomGameHandler(room)} className="btn btn-outline-success">Rejoindre</button></td>
            </tr>
        )
    }

    var allGames = [];
    if (currentGames.length > 0){
        if (currentGames.length > 50){
            currentGames.slice(0, 50)
        }
        allGames = currentGames.map((gameData) =>
            <div key={gameData["id"]} style={{display: "inline"}}>
                <GameList 
                    id={gameData["id"]}
                    score1={gameData["score1"]}
                    score2={gameData["score2"]}
                    name1={gameData["name1"]}
                    name2={gameData["name2"]}
                    board={JSON.parse(gameData["board"])}
                />
            </div>
        )
    }
    
    if (!isPlaying){
        return(
            <div className="lobby">
                <div className="container" style={{paddingBottom: "15px"}}>
                    <h2>ORDISSI-MOTS</h2>
                    <div className="buttons-container">
                        <button className="btn btn-outline-dark" onClick={() => createPublicGameHandler()}>Créer une partie</button>
                        {/* UNCOMMENT TO PLAY PRIVATE GAME */}
                        {/* <button className="btn btn-outline-dark" onClick={() => createPrivateGameHandler()}>Créer/Rejoindre une partie privée</button> */}
                    </div>
                    <table id="games-lobby" style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <th colSpan="3">Parties disponibles ({allRooms.length}) - Joueurs en ligne ({connectedPlayers})</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allRooms}
                        </tbody>
                    </table>

                    <p style={{ backgroundColor: "rgb(242, 145, 0)", color: "white" }}><strong>Parties en cours</strong></p>

                    <div style={{display: "inline", width:"100%"}}>
                        {allGames}
                    </div>
                    
                    {/* <h4>Aide</h4>
                    <p> 1) Créez votre propre partie et attendez qu'un autre joueur vous rejoigne, la partie commencera automatiquement</p>
                    <p> 2) Vous pouvez rejoindre n'importe quelle partie en cliquant sur 'Rejoindre'</p>
                    <p> 3) Lorsque le jeu démarre, cliquez sur "Piocher", cliquez ensuite sur une lettre et déposez là sur le plateau pour former un mot, cliquez ensuite sur "Valider"</p>
                    <p> 4) Si rien ne s'affiche, attendez patiemment votre tour!</p>
                    <p> 5) Amusez vous!</p> */}
                </div>
                <footer>
                    <p>Un jeu développé par <a style={{color: "white", textDecoration: "underline"}} href="mailto:ordissinaute@ordissimo.com?subject=ORDISSI-MOTS: Suggestion">Ordissimo</a></p>
                </footer>  
            </div>
        )
    }
    else{
        return (
            <div>
                <Game
                    socket={socket}
                    roomId={roomId}
                    opponentId={opponentId}
                    playerNames={playerNames}
                />
            </div>
        )
    }
    
}



function createPublicGameHandler(){
    var pseudo = "";
    var roomId = shortid.generate().substring(0, 6);
    Swal.fire({
        title: "Quel est votre pseudo?",
        input: "text",
        confirmButtonText: "Valider", 
        inputValidator: (input) => {
            if(input.length < 2){
                return "Entrez votre pseudo!"
            }
            pseudo = input
        }
    }).then((res) =>{
        if(res.isConfirmed){
            socket.emit('createRoom', roomId, false, pseudo);
            Swal.fire({
                title: "Patientez",
                text: "La partie commencera dès que quelqu'un vous aura rejoint",
                allowOutsideClick: false,
                confirmButtonText: "Quitter (Annuler la partie)",
                confirmButtonColor: "red"
            }).then((res) => {
                if (res.isConfirmed) {
                    socket.emit('leftRoomCreation', roomId);
                }
            })
        }
    })
    
}

// function createPrivateGameHandler() {
//     var roomId = shortid.generate().substring(0, 6);
//     socket.emit('createRoom', roomId, true);
//     Swal.fire({
//         title: "Partie privée",
//         html: '<p>Partagez ce code: <b>'+roomId+'</b> et patientez</p><p>Ou entrez le code que vous avec reçu</p>',
//         input: 'text',
//         allowOutsideClick: false,
//         confirmButtonText: "Rejoindre",
//         showDenyButton: true,
//         denyButtonText: "Quitter",
//         inputValidator: (input) => {
//             if(input.length === 6)
//             {
//                 socket.emit('joinRoom', input)
//             } 
//         }
//     }).then((res) => {
//         if(res.isDenied){
//             socket.emit('leftRoomCreation', roomId);
//         }
//     })
// }

function joindRandomGameHandler(roomId){
    var pseudo = ""
    Swal.fire({
        title: "Quel est votre pseudo?",
        input: "text",
        confirmButtonText: "Valider",
        inputValidator: (input) => {
            if (input.length < 2) {
                return "Entrez votre pseudo!"
            }
            pseudo = input
        }
    }).then((res) =>{
        if (res.isConfirmed){
            socket.emit('joinRoom', roomId, pseudo);
        }
    })   
}


export default Client