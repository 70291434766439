import React from 'react'
import Board from '../board/board';
import Help from '../help/help';
import './game.css'
import Informations from '../informations/informations';
import {Card, CardBody, Col, Row} from 'reactstrap'

class Game extends React.Component{
    getLetters(){
        var res = {
            "A": 9,
            "B": 2,
            "C": 2,
            "D": 3,
            "E": 15,
            "F": 2,
            "G": 2,
            "H": 2,
            "I": 8,
            "J": 1,
            "K": 1,
            "L": 5,
            "M": 3,
            "N": 6,
            "O": 6,
            "P": 2,
            "Q": 1,
            "R": 6,
            "S": 6,
            "T": 6,
            "U": 6,
            "V": 2,
            "W": 1,
            "X": 1,
            "Y": 1,
            "Z": 1,
            "_": 2
        };


        // var res = {
        //     "A": 5,
        //     "B": 1,
        //     "C": 5,
        //     "D": 1,
        //     "E": 5,
        //     "F": 5,
        //     "G": 1,
        //     "H": 1,
        //     "I": 5,
        //     "J": 1,
        //     "K": 1,
        //     "L": 5,
        //     "M": 5,
        //     "N": 5,
        //     "O": 5,
        //     "P": 1,
        //     "Q": 1,
        //     "R": 5,
        //     "S": 5,
        //     "T": 5,
        //     "U": 5,
        //     "V": 0,
        //     "W": 0,
        //     "X": 0,
        //     "Y": 0,
        //     "Z": 0,
        //     "_": 0
        // };
        return res;
    }
    
    constructor(props){
        super(props);
        var lettersBag = this.getLetters();
        this.state = 
        {
            array: Array(225).fill([null,null,null]),
            lettersBag: lettersBag
        }
    }


    render(){
        return (
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <h2 className="text-center">ORDISSI-MOTS</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Board
                                squares={this.state.array}
                                lettersBag={this.state.lettersBag}
                                socket={this.props.socket}
                                roomId={this.props.roomId}
                                opponentId={this.props.opponentId}
                                playerNames={this.props.playerNames}
                            />
                        </Col>
                        
                    </Row>
                </CardBody>
                <CardBody>
                    <Row>
                        <Col>
                            <Help />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Informations />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default Game
