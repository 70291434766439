import React, { useEffect, useRef, useState } from 'react';
import './chat.css';



function Chat(props) {

    const [text, setText] = useState('');
    const [messages, setMessages] = useState([])

    useEffect(() => { 
        props.socket.on('newMessages', (data) => {
            setMessages(data);
        })

    }, [props.socket])

    const sendText = () =>{
        if (text.trim() !== ''){
            props.socket.emit('sendMessage', text, props.roomId)
            setText('')
        } 
    }


    // Auto scroll when receive new message
    const recentMessageRef = useRef(null);
    const scrollToRecent = () => {
        recentMessageRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
    }
    useEffect(scrollToRecent, [messages]);

    return (
        <div className="chatbox">
            <div className="messages">
                {messages.map((elm) => { 
                    if(elm.author === props.socket.id){
                        return <p className='msg-right' >
                            <span >{elm.text}</span> <span style={{ fontSize: "9px" }}> {' '+ elm.date}</span>
                        </p>
                    }
                    else{
                        return <p className='msg-left'>
                            <span>{elm.text}</span>
                            <span style={{ fontSize: "9px" }}> {elm.date + ' '}</span>
                        </p>
                    }
                })}
                <div ref={recentMessageRef}></div>
            </div>

            <input
                placeholder="Entrez votre message"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        sendText();
                    }
                }}
            />
        </div>
    )
}

export default Chat;
