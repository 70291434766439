import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './controls.css';
import Swal from 'sweetalert2';

function Controls(props) {
    const [show, setShow] = useState(false);
    const listener = props.newTurnListener;
    const resumeGameListener = props.resumeGameListener
    const opponentBackListener = props.opponentBackListener;
    // eslint-disable-next-line

    let pStyle= {
        borderRadius: '8px',
        color: 'white',
        background: 'gray'
    }

    useEffect(() => {
        props.socket.on("initPlayers", () => {
            setShow(prevState => !prevState);
            
        });
        props.socket.on("refreshScore", (data) => {
            setShow(prevState => !prevState);
            data["board"] = JSON.parse(data["board"]);
            data["letters"] = JSON.parse(data["letters"]);
            listener(data);
        });
        props.socket.on("disconnect", () => {
            props.socket.disconnect();
        })

        props.socket.on('reconnectData', (data) =>{
            setShow(false);
            data["board"] = JSON.parse(data["board"]);
            data["letters"] = JSON.parse(data["letters"]);
            resumeGameListener(data);
        })
        props.socket.on('opponentIsBack', () =>{
            if(Swal.isVisible()){
                Swal.close();
                Swal.fire({
                    title: "Votre adversaire est revenu!",
                    confirmButtonText: "OK!"
                })
            }
            setShow(true);
            opponentBackListener();
        })
        props.socket.on("opponentLeft", () =>{
            window.gtag('event', 'Left game before end', {
                'event_category': 'scrabble',
                'event_label': 'Left game before end', 
                'value' : props.turn
            })
            if(!Swal.isVisible()){
                Swal.fire({
                    title: "Votre adversaire a quitté la partie...",
                    text: 'Vous pouvez l\'attendre ou quitter la partie aussi.',
                    denyButtonText: 'Quitter la partie',
                    showDenyButton: true,
                    showConfirmButton: false,
                    allowOutsideClick: false
                }).then((res) => {
                    if (res.isDenied) {
                        document.location.reload();
                        props.socket.emit('confirmKillGame');
                        localStorage.removeItem('roomId');
                        localStorage.removeItem('socketId');
                    }
                })
            }  
        })

        props.socket.on("confirmEndGame", () =>{
            Swal.fire({
                title: "Terminer la partie",
                text: "Votre adversaire ne peut plus jouer, et vous?",
                confirmButtonText: "Je peux jouer",
                showDenyButton: true,
                allowOutsideClick: false,
                denyButtonText: "Terminer la partie"
            }).then((res) =>{
                if(res.isConfirmed){
                    props.socket.emit('deniedEndGame', props.roomId)
                }
                if(res.isDenied){
                    props.socket.emit('confirmedEndGame', props.roomId)
                    window.gtag('event', 'Finished game', {
                        'event_category': 'scrabble',
                        'event_label': 'Finished game',
                        'value': props.turn
                    })
                }
            })
        })

        props.socket.on('cancelEndGame', (data) =>{
            setShow(prevState => !prevState);
            data["board"] = JSON.parse(data["board"]);
            data["letters"] = JSON.parse(data["letters"]);
            listener(data);
            Swal.close();
        })

        props.socket.on('win',() =>{
            Swal.fire({
                title: ("Félicitations, vous avez GAGNÉ!"),
                confirmButtonText: "Menu principal",
                allowOutsideClick: false
            }).then((res) => {
                if (res.isConfirmed) {
                    document.location.reload()
                }
            })
        });

        props.socket.on('loose', () =>{
            Swal.fire({
                title: ("Oh non, vous avez PERDU"),
                confirmButtonText: "Menu principal",
                allowOutsideClick: false
            }).then((res) => {
                if(res.isConfirmed){
                    document.location.reload()
                }
            })
        });

    },// eslint-disable-next-line
     [props.socket]) 


    if(show)
    {
        return (    
            <div className="controls">
                <div className="controls-buttons">
                    <span>
                        {props.getLetters}
                    </span>
                    {props.cancelTurn}
                    <span>
                        {props.passTurn}
                    </span>
                    <span>
                        {props.endTurn}
                    </span>
                </div>
                <p>À vous de jouer</p>
            </div>
        )
    }
    else{
        return(
        <div className="controls" style={{display: "initial"}}>
                <div className="controls-buttons"></div>
                <p style={pStyle }>C'est au tour de {props.nameOpponent}</p>
        </div>)
    }

}

export default Controls
