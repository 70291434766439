import React from 'react'

import Client from './client/client';

function App() {
    return (
        <div>
            <Client/>
        </div>
    )
}

export default App
