import React from 'react'
import './help.css'

function Help() {
    return (
        <div className="helper-left">    
            <table>
                <thead>
                    <tr>
                        <th colSpan="27">Points par lettre</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                            <th>A</th>
                            <th>B</th>
                            <th>C</th>
                            <th>D</th>
                            <th>E</th>
                            <th>F</th>
                            <th>G</th>
                            <th>H</th>
                            <th>I</th>
                            <th>J</th>
                            <th>K</th>
                            <th>L</th>
                            <th>M</th>
                            <th>N</th>
                            <th>O</th>
                            <th>P</th>
                            <th>Q</th>
                            <th>R</th>
                            <th>S</th>
                            <th>T</th>
                            <th>U</th>
                            <th>V</th>
                            <th>W</th>
                            <th>X</th>
                            <th>Y</th>
                            <th>Z</th>
                            <th>_</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>3</td>
                        <td>3</td>
                        <td>2</td>
                        <td>1</td>
                        <td>4</td>
                        <td>2</td>
                        <td>4</td>
                        <td>1</td>
                        <td>8</td>
                        <td>10</td>
                        <td>1</td>
                        <td>2</td>
                        <td>1</td>
                        <td>1</td>
                        <td>3</td>
                        <td>8</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>4</td>
                        <td>10</td>
                        <td>10</td>
                        <td>10</td>
                        <td>10</td>
                        <td>0</td>
                    </tr>     
                </tbody>
            </table>
        </div>
    )
}

export default Help
